<template>
  <div>
    <p v-if="cart.length == 0">Não há itens no carrinho</p>
    <h4 v-if="cart.length > 0">Itens do carrinho</h4>
    <vs-table
      ref="table"
      pagination
      :max-items="20"
      search
      :data="cart"
      v-if="cart.length > 0"
    >
      <template slot="thead">
        <vs-th>Item</vs-th>
        <vs-th>Qtd</vs-th>
        <vs-th>Ação</vs-th>
      </template>
      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <vs-td>
              {{ tr.name }}
            </vs-td>
            <vs-td>
              <vs-input
                type="number"
                :value="tr.qtd"
                min="1"
                v-model="tr.qtd"
              ></vs-input>
            </vs-td>
            <vs-td>
              <strong
                ><a href="#" @click="removeFromCart(indextr)"
                  >Remover</a
                ></strong
              >
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
    <p v-if="isAdmin && cart.length > 0" style="margin-top: 1em">
      Unidade Solicitante
    </p>
    <vs-select
      name="unitSelected"
      autocomplete
      class="w-full mb-6"
      v-model="unitSelected"
      v-if="isAdmin && cart.length > 0"
    >
      <vs-select-item
        :key="index"
        :value="item.id"
        :text="item.name"
        v-for="(item, index) in unitOptions"
      />
    </vs-select>

    <vs-button v-if="cart.length > 0" @click="submitOrder()">
      Registrar Pedido
    </vs-button>
  </div>
</template>

    <script>
import { notifyError, notifySuccess } from "../utils/helpers";
import { getDataInStorage, updateDataInStorage } from "../utils/storage";
import siriusAPI from "../services";
export default {
  data() {
    return {
      cart: [],
      isAdmin: false,
      unitOptions: [],
      unitSelected: 0,
    };
  },

  created() {
    let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
    const userProfile = loggedUser.profile.name;
    if (userProfile == "Administrador") {
      this.isAdmin = true;
      this.unitSelected = loggedUser.unit.id;
    }
  },

  mounted() {
    let storage = getDataInStorage();
    if (storage.cart) this.cart = storage.cart;
    else this.cart = [];
    this.loadUnits();
  },

  methods: {
    loadUnits() {
      new siriusAPI()
        .getUnits()
        .then((res) => {
          this.unitOptions = res.data;
        })
        .catch((error) => {
          if (error.response.data.statusCode == "401") {
            localStorage.clear();
            sessionStorage.removeItem("sirius-key");
            this.$router.push("/").catch(() => {});
            this.$acl.change(["public"]);
          } else {
            notifyError(
              this,
              "Erro ao tentar carregar as opções de unidades " + error
            );
          }
        });
    },
    removeFromCart(index) {
      this.cart.splice(index, 1);
      updateDataInStorage({ cart: this.cart });
    },
    submitOrder() {
      if (this.cart.length > 0) {
        //submit order
        let data = {
          requestUnitId: this.unitSelected,
          items: this.cart,
        };
        new siriusAPI()
          .submitOrder(data)
          .then(() => {
            notifySuccess(
              this,
              "Requisição submetida com sucesso! Consulte em Minhas Requisições."
            );
            this.cart = [];
            updateDataInStorage({ cart: this.cart });
            let loggedUser = JSON.parse(localStorage.getItem("userInfo"));
            const userProfile = loggedUser.profile.name;
            if (userProfile == "Administrador") {
              this.isAdmin = true;
              this.unitSelected = loggedUser.unit.id;
            }
          })
          .catch((error) => {
            if (error.response.data.statusCode == "401") {
              localStorage.clear();
              sessionStorage.removeItem("sirius-key");
              this.$router.push("/").catch(() => {});
              this.$acl.change(["public"]);
            } else {
              notifyError(
                this,
                "Erro ao tentar cadastrar a requisição: " + error
              );
            }
          });
      }
    },
  },
};
</script>
<style scoped>
.select1 {
  padding: 0.8em;
  border: 0.8px solid #ccc;
  border-radius: 5px;
}
.card {
  margin-bottom: 3em;
}
.border {
  border: 1px dotted #6666;
}
.bt {
  margin-right: 1em;
}
.duplicated {
  color: red;
}
</style>
